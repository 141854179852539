<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="view_employee" aria-labelledby="sidebar-no-header-title" no-header backdrop width="80rem" :style="`direction:`+lang.ldir" right :title="lang.view_employee" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.view_employee}}</span>
                    </div>
                    <div @click="hide" id="HideMe" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                 <v-divider></v-divider>
                 <div class="px-3 py-2" style="direction:rtl">
                    <v-row style="text-align:center;direction:rtl" class="m-2">
                        <v-col cols="12" style="text-align:end">
                            <div class="tobbuttom backBlack" style="padding:1px;width:100%;text-align:center;">
                                <b-button class="btn-sm btn-danger pa-1 m-2" style="border:1px solid #fff !important;font-size:1.1rem !important;min-width:150px;" v-b-toggle.emp_deducts @click="deducting()">{{ lang.deduct_from_salary }}</b-button>
                                <b-button class="btn-sm btn-warning pa-1 m-2" style="border:1px solid #fff !important;font-size:1.1rem !important;min-width:150px;background:#bf7b00;color:#FFF;" @click="payAdv()" v-b-toggle.advanced_paid>{{ lang.pay_advanced }}</b-button>
                                <b-button class="btn-sm btn-success pa-1 m-2" style="border:1px solid #fff !important;font-size:1.1rem !important;min-width:150px;" v-b-toggle.emp_reward @click="rewordedit()">{{ lang.add_reward }}</b-button>
                                <b-button class="btn-sm pa-1 m-2" style="display:none;border:1px solid #fff !important;font-size:1.1rem !important;min-width:150px;background:darkblue;color:#FFF;" @click="custAdv()" v-b-toggle.emp_custody>{{ lang.add_custody }}</b-button>
                                <b-button class="btn-sm pa-1 m-2" style="border:1px solid #fff !important;font-size:1.1rem !important;min-width:150px;background:#115860;color:#FFF;" @click="fixIt()" v-if="salaryregisterd == 0" v-b-toggle.fix_salary>{{ lang.post_salary }}</b-button>
                                <!-- <b-button class="btn-sm pa-1 m-2" style="min-width:150px;background:#115860;color:#FFF;" v-if="restSalary > 0" @click="payForIt()" v-b-toggle.salary_paid>سند صرف راتب </b-button> -->
                            </div>
                        </v-col>
                    </v-row>
                    <v-row style="text-align:center;direction:rtl" class="m-2">
                        <v-col cols="12" md="4" sm="6">
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th style="width:45%;" class="backBlack">{{ lang.start_date }}</th>
                                        <td class="fborder">{{ startdate }}</td>
                                    </tr>
                                </thead>
                            </b-table-simple>
                        </v-col>
                        <v-col cols="12" md="4" sm="6">
                            
                        </v-col>
                        <v-col cols="12" md="4" sm="6">
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th style="width:45%;" class="backBlack">{{lang.indemnity}}</th>
                                        <td class="fborder">{{endofservices}} SAR</td>
                                    </tr>
                                </thead>
                            </b-table-simple>
                        </v-col>
                    </v-row>
                    <v-row style="text-align:center;direction:rtl" class="m-2">
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="2">
                                    <label>{{ lang.select_month }}</label>
                                    <b-input-group>
                                    <b-form-select
                                        v-model="month"
                                        :options="months"
                                        class="selborder"
                                        style="width:200px;"
                                        @change="getEmployees()"
                                    ></b-form-select>
                                        <b-input-group-addon style="margin-right:-35px;margin-top:5px;">
                                            <i class="fas fa-arrow-down"></i>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </v-col>
                                <v-col cols="2">
                                    <label>{{ lang.select_year }}</label>
                                    <b-form-input class="inborder"
                                    label="السنة"
                                    v-model="year"
                                    type="number"
                                    style="width:200px;"
                                    @change="getEmployees()"
                                    >{{ year }}</b-form-input>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="11" v-if="salaryregisterd == 1">
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th class="backBlack">{{lang.posted_salary}}</th>
                                    </tr>
                                    <tr>
                                        <!-- <th class="backBlack">رصيد سابق</th> -->
                                        <th class="backBlack">{{ lang.base_salary }}</th>
                                        <th class="backRed">{{lang.emp_deducts}}</th>
                                        <th class="backRed">{{lang.paid_in_advanced}}</th>
                                        <th class="backGreen">{{ lang.custody }}</th>
                                        <th class="backGreen" v-if="$store.state.licenseType.cars">{{ lang.card_percentage }}</th>
                                        <th class="backGreen">{{lang.invoice_percentage}}</th>
                                        <th class="backGreen">{{ lang.remain_salary }}</th>
                                        <th class="backBlack">{{lang.notes}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <!-- <td>{{}}</td> -->
                                        <td>{{rsalary[0].stotal}}</td>
                                        <td>{{rsalary[0].discounts}}</td>
                                        <td>{{rsalary[0].padvanced}}</td>
                                        <td>{{rsalary[0].bonuce}}</td>
                                        <td v-if="$store.state.licenseType.cars">{{rsalary[0].ptotal}}</td>
                                        <td>{{rsalary[0].ipercentage}}</td>
                                        <td>{{rsalary[0].paidsalary}}</td>
                                        <td>{{rsalary[0].notes}}</td>
                                    </tr>
                                </tbody>
                            </b-table-simple>
                        </v-col>
                        <v-col cols="12" md="4">
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th style="width:45%;" class="backBlack">{{ lang.emp_name }}</th>
                                        <td class="fborder">{{ employees.employee_name }}</td>
                                    </tr>
                                    <tr>
                                        <th class="backBlack">{{lang.mobile}}</th>
                                        <td class="fborder">{{ employees.mobile }}</td>
                                    </tr>
                                    <tr>
                                        <th class="backGreen">{{lang.lated_salaries}}</th>
                                        <td class="fborder">{{ restSalary }}</td>
                                    </tr>
                                    <tr>
                                        <th class="backGreen">{{ lang.basic_salary }} </th>
                                        <td class="fborder">{{ employees.salary }}</td>
                                    </tr>
                                    <tr>
                                        <th class="backBlack">{{lang.home_allownance}}</th>
                                        <td class="fborder">{{ employees.home_allown }}</td>
                                    </tr>
                                    <tr>
                                        <th class="backBlack">{{ lang.trans_allownance }}</th>
                                        <td class="fborder">{{ employees.tarns_allown }}</td>
                                    </tr>
                                    <tr>
                                        <th class="backBlack">{{ lang.food_allown }}</th>
                                        <td class="fborder">{{ employees.food_allown }}</td>
                                    </tr>
                                    <tr>
                                        <th class="backBlack">{{ lang.other_allown }}</th>
                                        <td class="fborder">{{ employees.others_allown }}</td>
                                    </tr>
                                    <tr>
                                        <th class="backBlack">{{ lang.salary_total }}</th>
                                        <td class="fborder">{{ employees.stotal }}</td>
                                    </tr>
                                    <tr>
                                        <th class="backRed">{{ lang.emp_deducts }}</th>
                                        <td class="fborder">{{discounts}}</td>
                                    </tr>
                                    <tr>
                                        <th class="backRed">{{lang.pay_advanced}}</th>
                                        <td class="fborder">{{padvanced}}</td>
                                    </tr>
                                    <tr>
                                        <th class="backGreen">{{lang.custdy}}</th>
                                        <td class="fborder">{{bonuce}}</td>
                                    </tr>
                                    <tr v-if="$store.state.licenseType.cars">
                                        <th class="backBlack">{{lang.card_percentage}}</th>
                                        <td class="fborder">{{ptotal}}</td>
                                    </tr>
                                    <tr>
                                        <th class="backBlack">{{lang.invoice_percentage}}</th>
                                        <td class="fborder">{{ipercentage}}</td>
                                    </tr>
                                    <tr>
                                        <th class="backGreen">{{lang.remain_salary}}</th>
                                        <td class="fborder">{{ netsalary }}</td>
                                    </tr>
                                </thead>
                            </b-table-simple>
                        </v-col>
                        <v-col cols="12" md="8">
                            <b-table-simple v-if="$store.state.licenseType.cars">
                                <thead>
                                    <tr>
                                        <th colspan="8" class="backBlack">{{ lang.mentanace_card }}</th>
                                    </tr>
                                    <tr>
                                        <th>{{lang.cardid}}</th>
                                        <th>{{ lang.received_date }}</th>
                                        <th>{{lang.customer_name}}</th>
                                        <th>{{lang.plate_number}}</th>
                                        <th>{{lang.service}}</th>
                                        <th>{{lang.deduct}}</th>
                                        <th>{{lang.remain}}</th>
                                        <th>{{lang.percentage}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in cardsrows" :key="index">
                                        <td>{{item.cardid}}</td>
                                        <td>{{item.date_in}}</td>
                                        <td>{{item.customer_name}}</td>
                                        <td>{{item.plate_number}}</td>
                                        <td>{{item.service}}</td>
                                        <td>{{item.expense}}</td>
                                        <td>{{item.setotal}}</td>
                                        <td style="background:lightgreen">{{item.percentage}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th class="backEmpty"></th>
                                        <th class="backEmpty"></th>
                                        <th class="backEmpty"></th>
                                        <th class="backBlack">{{ lang.total }}</th>
                                        <th class="backBlack">{{stotal}}</th>
                                        <th class="backBlack">{{extotal}}</th>
                                        <th class="backBlack">{{csubtotal}}</th>
                                        <th class="backGreen">{{ptotal}}</th>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th colspan="6" class="backBlack">{{lang.invoices}}</th>
                                    </tr>
                                    <tr>
                                        <th class="">{{lang.invoiceno}}</th>
                                        <th class="">{{lang.invoice_date}}</th>
                                        <!-- <th>اسم العميل</th> -->
                                        <th class="">{{lang.total}}</th>
                                        <th class="">{{lang.expenses}}</th>
                                        <th class="">{{lang.remain}}</th>
                                        <th class="">{{lang.percentage}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in invoicerows" :key="index">
                                        <td>{{item.invoice_number}}</td>
                                        <td>{{item.inv_date}}</td>
                                        <td>{{item.total}}</td>
                                        <td>{{item.expense}}</td>
                                        <td>{{item.remain}}</td>
                                        <td style="background:lightgreen">{{item.percentage}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th class="backEmpty"></th>
                                        <th class="backBlack">{{lang.total}}</th>
                                        <th class="backBlack">{{itotal}}</th>
                                        <th class="backBlack">{{iexpense}}</th>
                                        <th class="backBlack">{{iremain}}</th>
                                        <th class="backGreen">{{ipercentage}}</th>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th colspan="7" class="backBlack">{{lang.paid_in_advanced}}</th>
                                    </tr>
                                    <tr>
                                        <th class="">{{lang.date}}</th>
                                        <th class="">{{lang.paid_in_advanced_total}}</th>
                                        <th class="">{{ lang.number_of_parts }}</th>
                                        <th class="">{{lang.part_amount}}</th>
                                        <th class="">{{lang.returned}}</th>
                                        <th class="">{{lang.remain}}</th>
                                        <th class="">{{lang.delete}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in payadvanced" :key="index">
                                        <td>{{item.date_received}}</td>
                                        <td>{{item.amount}}</td>
                                        <td>{{item.installment_number}}</td>
                                        <td v-html="$RoundNum(parseFloat(item.amount) / parseFloat(item.installment_number))"></td>
                                        <td>{{item.paid_amount}}</td>
                                        <td>{{item.rest_amount}}</td>
                                        <td style="cursor:pointer;margin-left: 5px;width:80px;background:darkred;border:2px solid #fff !important;border-top:2px solid #fff !important;">
                                            <div class="viewBTN" style="background:darkred !important;" @click="deletePayAdv(item)">
                                            {{lang.delete}}</div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th class="backBlack">{{lang.total}}</th>
                                        <th class="backBlack">{{installment_total}}</th>
                                        <th class="backBlack">-</th>
                                        <th class="backBlack">{{installment_amount}}</th>
                                        <th class="backBlack">{{installment_paid}}</th>
                                        <th class="backBlack">{{installment_rest}}</th>
                                        <th class="backBlack"></th>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th colspan="4" class="backBlack">{{lang.emp_reward}}</th>
                                    </tr>
                                    <tr>
                                        <th class="">{{lang.date}}</th>
                                        <th class="">{{lang.amount}}</th>
                                        <th class="">{{lang.reward_resone}}</th>
                                        <th class="">{{lang.status}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in rewarded" :key="index">
                                        <td>{{item.date_received}}</td>
                                        <td style="background:lightgreen">{{item.amount}}</td>
                                        <td>{{item.reward_resone}}</td>
                                        <td v-html="item.status == 1 ? lang.notpaid : (item.status == 2 ? lang.paid : lang.canceled)"></td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td class="backBlack">{{lang.totals}}</td>
                                        <td class="backGreen">{{rewtotals}}</td>
                                        <td class="backEmpty"></td>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                            <b-table-simple>
                                <thead>
                                    <tr>
                                        <th colspan="3" class="backBlack">{{lang.deduct}}</th>
                                    </tr>
                                    <tr>
                                        <th class="">{{lang.date}}</th>
                                        <th class="">{{lang.amount}}</th>
                                        <th class="">{{lang.deduct_resone}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in deducts" :key="index">
                                        <td>{{item.date_received}}</td>
                                        <td style="background:lightgreen">{{item.amount}}</td>
                                        <td>{{item.reward_resone}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td class="backBlack">{{lang.totals}}</td>
                                        <td class="backRed">{{dedtotals}}</td>
                                        <td class="backEmpty"></td>
                                    </tr>
                                </tfoot>
                            </b-table-simple>
                            
                        </v-col>
                    </v-row>
                 </div>
            </template>
        </b-sidebar>
        </v-form>
        <advanced-paid ref="payAdvanced" />
        <employee-reward ref="rewordedit" />
        <employee-deduct ref="deducting" />
        <employee-emp-custody ref="custAdvanced" />
        <fix-salary ref="fixsalary" />
        
    </div>
</template>

<script>
import axios from 'axios'
import AdvancedPaid from '@/components/advanced-paid.vue';
import EmployeeReward from '@/components/employee-reward.vue';
import EmployeeDeduct from '@/components/employee-deduct.vue';
import EmployeeEmpCustody from '@/components/employee-emp-custody.vue';
import FixSalary from '@/components/fix-salary.vue';
import {SnotifyPosition} from 'vue-snotify';
export default {
    components: {AdvancedPaid,EmployeeReward,EmployeeDeduct,EmployeeEmpCustody,FixSalary },
    data() {
        return {
            totalpaid: 0,
            restSalary: 0,
            totalSalary: 0,
            empid: 0,
            employee:{},
            employees:{
                startdate: '',
            },
            cardsrows: [],
            invoicerows: [],
            rewarded: [],
            payadvanced: [],
            deducts: [],
            custodies: [],
            endofservice: 0,
            months: [
                {text: "January",value:1},
                {text: "February",value:2},
                {text: "March",value:3},
                {text: "April",value:4},
                {text: "May",value:5},
                {text: "June",value:6},
                {text: "July",value:7},
                {text: "August",value:8},
                {text: "September",value:9},
                {text: "October",value:10},
                {text: "November",value:11},
                {text: "December",value:12},
            ],
            month: '',
            year: 2022,
            rsalary: [],
            salaryregisterd: 0
        }
    },
    methods: {
        fixIt(){
            this.$refs.fixsalary.empid = this.empid
            this.$refs.fixsalary.doitall()
        },
        payAdv(){
            this.$refs.payAdvanced.empid = this.empid
        },
        rewordedit(){
            this.$refs.rewordedit.empid = this.empid
        },
        deducting(){
            this.$refs.deducting.empid = this.empid
        },
        custAdv(){
            this.$refs.custAdvanced.empid = this.empid
        },
        payForIt(){
            this.$refs.addEmployeePayment.empid = this.empid
            this.$refs.addEmployeePayment.restSalary = this.restSalary
        },
        getMonth(){
            let tm = new Date();
            this.month = parseFloat(tm.getMonth()) + parseFloat(1);
            this.year = tm.getFullYear()
        },
        getEmployees()
        {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getAllEmp");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.empid);
            post.append('data[month]',this.month);
            post.append('data[year]',this.year);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // // console.log(response.data);
                const res = response.data.results.data;
                this.empid = res[0].id;
                this.totalSalary = res[0].totalSalary;
                this.totalpaid = res[0].totalpaid;
                this.restSalary = res[0].restSalary;
                this.employees = res[0];
                this.cardsrows = res[0].cards;
                this.invoicerows = res[0].invoices;
                this.payadvanced = res[0].payadvanced;
                this.rewarded = res[0].rewarded;
                this.deducts = res[0].deducts;
                this.custodies = res[0].custodies;
                this.rsalary = res[0].paidsalary;
                this.salaryregisterd = res[0].salaryregisterd;
            })
        },
        deletePayAdv(item){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "deleteAdvanced");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.empid);
            post.append('data[advid]',item.id);
            post.append('data[date_received]',item.date_received);
            post.append('data[amount]',item.amount);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                // // console.log(response.data);
                const res = response.data.results.data;
                let message = this.lang.advanced_payment_deleted;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.cancel, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                                // document.getElementById('addModalBTN').click()
                            } 
                        },
                    ]
                });
                this.getEmployees();
            })
        }
    },
   
    created() {
        // this.getEmployees();
        this.getMonth();
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        paidsalary: function(){
            // // console.log(this.rsalary[0]);
            if(this.rsalary.length > 0)
                return this.rsalary[0].paidsalary;
            
            return 0;
        },
        stotal: function() {
            let t = 0;
            for(let i=0;i<this.cardsrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardsrows[i].service);
            }
            return this.$RoundNum(t);
        },
        csubtotal: function() {
            let t = 0;
            for(let i=0;i<this.cardsrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardsrows[i].setotal);
            }
            return this.$RoundNum(t);
        },
        extotal: function() {
            let t = 0;
            for(let i=0;i<this.cardsrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardsrows[i].expense);
            }
            return this.$RoundNum(t);
        },
        startdate: function() {
            return this.employees.startdate;
        },
        ptotal: function() {
            let t = 0;
            for(let i=0;i<this.cardsrows.length;i++){
                t = parseFloat(t) + parseFloat(this.cardsrows[i].percentage);
            }
            return this.$RoundNum(t);
        },
        itotal: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].total);
            }
            return this.$RoundNum(t);
        },
        ivat: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].vat);
            }
            return this.$RoundNum(t);
        },
        iftotal: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].ftotal);
            }
            return this.$RoundNum(t);
        },
        iexpense: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].expense);
            }
            return this.$RoundNum(t);
        },
        iremain: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].remain);
            }
            return this.$RoundNum(t);
        },
        ipercentage: function(){
            let t = 0;
            for(let i=0;i<this.invoicerows.length;i++){
                t = parseFloat(t) + parseFloat(this.invoicerows[i].percentage);
            }
            return this.$RoundNum(t);
        },
        netsalary: function() {
            let t= 0;
            t = parseFloat(this.employees.stotal) + parseFloat(this.ipercentage) + parseFloat(this.ptotal);
            t = parseFloat(t) - parseFloat(this.discounts) - parseFloat(this.padvanced) + parseFloat(this.bonuce)
            return this.$RoundNum(t);
        },
        installment_total: function(){
            let t = 0;
            for(let i=0;i<this.payadvanced.length;i++){
                t = parseFloat(t) + parseFloat(this.payadvanced[i].amount);
            }
            return this.$RoundNum(t);
        },
        installment_amount: function(){
            let t = 0;
            for(let i=0;i<this.payadvanced.length;i++){
                t = parseFloat(t) + parseFloat(this.$RoundNum(parseFloat(this.payadvanced[i].amount) / parseFloat(this.payadvanced[i].installment_number)));
            }
            return this.$RoundNum(t);
        },
        installment_paid: function(){
            let t = 0;
            for(let i=0;i<this.payadvanced.length;i++){
                t = parseFloat(t) + parseFloat(this.payadvanced[i].paid_amount);
            }
            return this.$RoundNum(t);
        },
        installment_rest: function(){
            let t = 0;
            for(let i=0;i<this.payadvanced.length;i++){
                t = parseFloat(t) + parseFloat(this.payadvanced[i].rest_amount);
            }
            return this.$RoundNum(t);
        },
        bonuce: function(){
            let t = 0;
            t = this.rewtotals;
            return this.$RoundNum(t);
        },
        discounts: function(){
            let t = 0;
            t = this.dedtotals;
            return this.$RoundNum(t);
        },
        padvanced: function(){
            let t = 0;
            for(let i=0;i<this.payadvanced.length;i++){
                if(this.payadvanced[i].rest_amount > 0)
                    t = parseFloat(t) + parseFloat(this.$RoundNum(parseFloat(this.payadvanced[i].amount) / parseFloat(this.payadvanced[i].installment_number)));
            }
            return this.$RoundNum(t);
        },
        rewtotals: function(){
            let t = 0;
            for(let i=0;i<this.rewarded.length;i++){
                t = parseFloat(t) + parseFloat(this.rewarded[i].amount);
            }
            return this.$RoundNum(t);
        },
        dedtotals: function(){
            let t = 0;
            for(let i=0;i<this.deducts.length;i++){
                t = parseFloat(t) + parseFloat(this.deducts[i].amount);
            }
            return this.$RoundNum(t);
        },
        custotals: function(){
            let t = 0;
            for(let i=0;i<this.custodies.length;i++){
                t = parseFloat(t) + parseFloat(this.custodies[i].amount);
            }
            return this.$RoundNum(t);
        },
        cusptotals: function(){
            let t = 0;
            for(let i=0;i<this.custodies.length;i++){
                t = parseFloat(t) + parseFloat(this.custodies[i].paid);
            }
            return this.$RoundNum(t);
        },
        cusrtotals: function(){
            let t = 0;
            for(let i=0;i<this.custodies.length;i++){
                t = parseFloat(t) + parseFloat(this.custodies[i].remaining);
            }
            return this.$RoundNum(t);
        },
        btotal: function(){
            return this.employees.stotal;
        },
        endofservices: function() {
            let t = 0;
            const date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString();
            t = this.$EndService(this.$GetTimeStamp(this.startdate), this.$GetTimeStamp(date) , this.btotal)
            return this.$RoundNum(t);
        },
    }
}
</script>